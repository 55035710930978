<template>
  <v-app>
    <v-container>
    </v-container>
  </v-app>
</template>

<script>
export default {
  props: {
    auth: Object,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>

<style></style>
